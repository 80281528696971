var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',{attrs:{"align":"end","justify":"space-between","no-gutters":""}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('GoBack'),_c('p',{staticClass:"header-text"},[_vm._v(" "+_vm._s(_vm.studentData.user.full_name)+" ")]),_c('p',{staticClass:"lightCarbon--text font-weight-regular caption"},[_vm._v(" "+_vm._s(_vm.studentData.user.email)+" · "+_vm._s(_vm.studentData.batch_name)+" ")])],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"px-14 button-start-checking",attrs:{"color":"primary","depressed":"","min-height":"55","min-width":"60","to":{
          name: 'StartChecking',
          query: {
            studentId: _vm.studentId,
          },
        }}},[_vm._v(" Start Checking ")]),_c('p',{staticClass:"mt-1 text-center font-weight-medium text--secondary caption"},[_vm._v(" Start checking assignments of this student ")])],1)],1),_c('v-divider',{staticClass:"mt-2 mb-4"}),_c('v-row',{staticClass:"my-6 mb-16",attrs:{"dense":""}},_vm._l((_vm.cards),function(item,index){return _c('v-col',{key:index,attrs:{"cols":"12","lg":"3","md":"6"}},[_c('AnalyticsCard',{attrs:{"body":item.body,"color":item.color,"title":item.title}})],1)}),1),_c('div',{staticClass:"my-6"},[_c('ViewStudentSearch',{attrs:{"get-students-data":_vm.getStudentsData,"reset":_vm.resetPageNo}})],1),_c('v-data-table',{attrs:{"custom-sort":() => _vm.viewStudents.data.data,"headers":_vm.headers,"hide-default-footer":"","items":_vm.viewStudents.data.data,"loading":_vm.listLoading,"multi-sort":"","sort-by":_vm.viewStudents.sort.fields,"sort-desc":_vm.viewStudents.sort.desc},on:{"update:options":_vm.handleSort},scopedSlots:_vm._u([{key:`header.action`,fn:function(){return [_c('span',{staticClass:"subtitle-2 lightCarbon--text"},[_vm._v(" "+_vm._s(_vm.totalEntries)+" ")])]},proxy:true},{key:`item.assignment__title`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("formatAssignmentTitle")(item.assignment.title,item.assignment.short_title))+" ")]}},{key:`item.submitted_at`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.submitted_at ? _vm.moment(item.submitted_at).format("ll") : "-")+" ")]}},{key:`item.courses`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("formatCourses")(item.courses))+" ")]}},{key:`item.status`,fn:function({ item }){return [(item.review?.result === 'passed')?_c('span',{staticClass:"green--text"},[_vm._v(" Passed ")]):(item.review?.result === 'failed')?_c('span',{staticClass:"red--text"},[_vm._v(" Failed ")]):(!item.status)?_c('span',{staticClass:"red--text"},[_vm._v(" Not Reviewed ")]):(
          item.status === 'not_reviewed' || item.status === 'under_review'
        )?_c('span',{staticClass:"orange--text d-flex align-center"},[_vm._v(" Pending "),(item.order > 1)?_c('v-icon',{staticClass:"re-submission-icon",attrs:{"color":"white","size":"20"}},[_vm._v(" mdi-refresh ")]):_vm._e()],1):(item.status === 'accepted')?_c('span',{staticClass:"green--text"},[_vm._v(" Accepted ")]):(item.status === 'rejected')?_c('span',{staticClass:"red--text"},[_vm._v(" Rejected ")]):(item.status === 'plagiarised')?_c('span',{staticClass:"red--text"},[_vm._v(" Plagiarised ")]):_vm._e()]}},{key:`item.review`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.review && item.review.professor ? item.review.professor : "-")+" ")]}},{key:`item.action`,fn:function({ item }){return [_c('v-btn',{attrs:{"color":"primary","small":"","text":"","to":_vm.getLink(item.uuid)}},[_vm._v(" VIEW SUBMISSION ")])]}},{key:"footer",fn:function(){return [_c('Paginator',{attrs:{"page":_vm.viewStudents.page,"total":_vm.viewStudents.data.total},on:{"change":_vm.handlePagination}})]},proxy:true}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }